<template>
  <div>
    <SmallNav />
    <div class="container">
      <div class="project-top">
        <h4 class="back_text" @click="back">
          <img src="../assets/images/safe.svg" alt="safe" />
          <span>Back</span>
        </h4>
        <div
          v-if="projectDetails.submitted === 'YES'"
          class="fund_link"
          data-toggle="modal"
          data-target="#fundproject"
        >
          <h6 class="btn">
            <span>Fund Project</span>
            <img src="../assets/images/safe.svg" alt="safe" />
          </h6>
        </div>
      </div>

      <div v-if="loading">
        <PageLoader></PageLoader>
      </div>

      <div v-else class="details_body container">
        <div class="details_top container">
          <h6>{{ projectDetails.title }}</h6>
          <h3 v-if="projectDetails.amounttype === 'fixed'">
            ₦{{ Number(projectDetails.amountforfixed).toLocaleString() }}
          </h3>
          <h3 v-if="projectDetails.amounttype === 'range'">
            ₦{{ Number(projectDetails.maxamountforrange).toLocaleString() }}
          </h3>
          <small>Last funded: {{ project.lasttransactiondate | moment }}</small>
          <small class="pt-2" v-if="pi >= 60"
            >You are eligible for gap financing <span>?</span></small
          >
          <small class="pt-2" v-else>Keep going up Champ!</small>
          <hr class="mt-4 mb-3" />

          <h5
            v-if="
              projectDetails.projectdetails != null ||
                projectDetails.projectdetails != ''
            "
          >
            {{ projectDetails.projectdetails }}
          </h5>
          <div class="indicies">
            <div class="PI">
              PI-{{ pi }}%
              <span class="pi-tooltip"
                >?
                <p class="tooltiptext pitip">
                  Your Performance Index (PI) is automatically calculated based
                  on your credit score and how well you follow your project
                  plan.
                </p>
              </span>
            </div>
            <div class="PI">
              APF-{{ apf }}%
              <span class="pi-tooltip"
                >?
                <p class="tooltiptext apftip">
                  Your Accumulated Project funds (APF) is automatically
                  calculated based on your deposit to your project. plan.
                </p>
              </span>
            </div>
            <div class="PI">
              CS-{{ cs }}%
              <span class="pi-tooltip"
                >?
                <p class="tooltiptext CS">
                  Your Credit Score (CS) is automatically calculated based on
                  your credit score and how well you follow your project plan.
                </p>
              </span>
            </div>
          </div>
        </div>
        <form class="">
          <div class="container">
            <div class="row details-row">
              <div class="col-md-4 col-sm-12">
                <div class="form-group">
                  <div class="form-control theDiv">
                    <small>Project Amount</small>
                    <p v-if="projectDetails.amounttype === 'fixed'">
                      ₦{{
                        Number(projectDetails.amountforfixed).toLocaleString()
                      }}
                    </p>
                    <p v-if="projectDetails.amounttype === 'range'">
                      ₦{{
                        Number(
                          projectDetails.maxamountforrange
                        ).toLocaleString()
                      }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-4 col-sm-12">
                <div class="form-group">
                  <div class="form-control theDiv">
                    <small>Accumulated Project Funds</small>
                    <p>
                      ₦{{ Number(AccumulatedProjectFund).toLocaleString() }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-4 col-sm-12">
                <div class="form-group">
                  <div class="form-control theDiv">
                    <small>Amount Left</small>
                    <p>₦{{ Number(project.amountleft).toLocaleString() }}</p>
                  </div>
                </div>
              </div>

              <div class="col-md-4 col-sm-12">
                <div class="form-group">
                  <div class="form-control theDiv">
                    <small>Allocation/Amount/Budget</small>
                    <p>{{ projectDetails.amounttype }}</p>
                  </div>
                </div>
              </div>

              <div class="col-md-4 col-sm-12">
                <div class="form-group">
                  <div class="form-control theDiv">
                    <small>Start Date</small>
                    <p>{{ projectDetails.startdate }}</p>
                  </div>
                </div>
              </div>
              <div class="col-md-4 col-sm-12">
                <div class="form-group">
                  <div class="form-control theDiv">
                    <small>Deadline</small>
                    <p>{{ projectDetails.enddate }}</p>
                  </div>
                </div>
              </div>
              <div class="col-md-4 col-sm-12">
                <div class="form-group">
                  <div class="form-control theDiv">
                    <small>Status</small>
                    <p
                      :class="[
                        projectDetails.status == 'active'
                          ? 'active_status'
                          : '',
                        projectDetails.status == 'inactive'
                          ? 'inactive_status'
                          : '',
                        ,
                        projectDetails.status == 'completed'
                          ? 'completed_status'
                          : ''
                      ]"
                    >
                      {{ projectDetails.status }}
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-md-4 col-sm-12">
                <div class="form-group">
                  <div class="form-control theDiv">
                    <small>Funding Option</small>
                    <p v-if="projectDetails.fundtype == 'fromsafe'">
                      Fund From SAFE
                    </p>
                    <p v-else>Fund At Will</p>
                  </div>
                </div>
              </div>
              <div class="col-md-4 col-sm-12">
                <div class="form-group">
                  <div class="form-control theDiv">
                    <small>Frequency</small>
                    <p v-if="projectDetails.modeofsafing == 1">Daily</p>
                    <p v-if="projectDetails.modeofsafing == 2">Weekly</p>
                    <p v-if="projectDetails.modeofsafing == 3">Monthly</p>
                  </div>
                </div>
              </div>
              <div class="col-md-4 col-sm-12">
                <div class="form-group">
                  <div class="form-control theDiv">
                    <small v-if="projectDetails.modeofsafing == 1"
                      >Amount Payable (Daily)</small
                    >
                    <small v-if="projectDetails.modeofsafing == 2"
                      >Amount Payable (Weekly)</small
                    >
                    <small v-if="projectDetails.modeofsafing == 3"
                      >Amount Payable (Monthly)</small
                    >
                    <p>
                      ₦{{
                        Number(
                          project.payable_amount
                        ).toLocaleString()
                      }}
                    </p>
                    <!-- <p v-if="projectDetails.amounttype === 'fixed'">
                      ₦{{
                        Number(
                          projectDetails.amountforfixed / monthly
                        ).toLocaleString()
                      }}
                    </p>
                    <p v-if="projectDetails.amounttype === 'range'">
                      ₦{{
                        Number(
                          projectDetails.maxamountforrange / monthly
                        ).toLocaleString()
                      }}
                    </p> -->
                    <!-- <p>
                      &#8358;{{ Number(amount * monthly).toLocaleString() }}
                    </p> -->
                  </div>
                </div>
              </div>
              <div class="col-md-4 col-sm-12">
                <div class="form-group">
                  <div class="form-control theDiv">
                    <small>Project Type</small>
                    <p>{{ project.project_type }}</p>
                    <!-- <p v-if="projectDetails.projecttypeid == 0">Rent</p>
                    <p v-if="projectDetails.projecttypeid == 1">
                      Property Ownership
                    </p>
                    <p v-if="projectDetails.projecttypeid == 2">Renovate</p> -->
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="container button-row">
            <div class="button_holder">
              <button
                v-if="
                  projectDetails.paymentprocesscompleted == 'NO' &&
                    projectEnded &&
                    projectDetails.ended_and_collected_fund == 'NO' &&
                    projectDetails.ended_and_transfered_to_project_id == 'NO' &&
                    projectDetails.ended_and_transfered_to_safe == 'NO' &&
                    projectDetails.ended_payment_made == 'NO' &&
                    projectDetails.status == 'active'
                "
                @click.prevent=""
                class="btn end_project"
                data-toggle="modal"
                data-target="#endproject"
              >
                End Project
              </button>
              <button
                :disabled="
                  pi < 30 ||
                    projectDetails.payoutsubmitted === 'YES' ||
                    projectEndDateDuration < 1
                "
                class="btn payout"
                @click.prevent=""
                data-toggle="modal"
                data-target="#payout"
              >
                Payout
              </button>
            </div>
          </div>
          <div class="status container">
            <div class="header-status">
              <h5>Status update</h5>
              <router-link to="/dashboard/safetransaction">
                <h6>Transactions history</h6>
              </router-link>
            </div>

            <div class="status-section">
              <div class="status-set">
                <div class="status-mark">
                  <span
                    class="completed-mark"
                    :class="[
                      projectDetails.complete_with_gap_financing == 'YES'
                        ? 'completed-mark'
                        : 'failed-mark'
                    ]"
                  >
                    <img
                      v-if="projectDetails.complete_with_gap_financing == 'YES'"
                      src="../assets/icons/check-w.svg"
                      alt=""
                    />
                    <img
                      v-else
                      src="../assets/icons/alert-triangle.svg"
                      alt=""
                    />
                    <div class="c-strike"></div>
                  </span>
                  <div>
                    <p>Beneficiary account verification</p>
                    <small
                      v-if="
                        projectDetails.complete_with_gap_financing == 'YES' &&
                          projectDetails.beneficiary_verified_date != null
                      "
                      >{{
                        projectDetails.beneficiary_verified_date | moment
                      }}</small
                    >
                    <small v-if="projectDetails.beneficiary_verification_status != 'verified'"
                      >Wrong data entry
                      <a
                        @click="
                          updateObject();
                          updateB = true;
                          updateG = false;
                        "
                        href=""
                        data-toggle="modal"
                        data-target="#updateBG"
                        >Update</a
                      ></small
                    >
                  </div>
                </div>
                <h6
                  v-if="projectDetails.complete_with_gap_financing == 'YES'"
                  class=""
                  :class="[
                    projectDetails.beneficiary_verification_status == 'pending'
                      ? 'pending-text'
                      : 'completed-text',
                    projectDetails.beneficiary_verification_status == 'failed'
                      ? 'failed-text'
                      : ''
                  ]"
                >
                  {{ projectDetails.beneficiary_verification_status }}
                </h6>
              </div>
              <div class="status-set">
                <div class="status-mark">
                  <span
                    class=""
                    :class="[
                      projectDetails.complete_with_gap_financing == 'YES'
                        ? 'completed-mark'
                        : 'failed-mark'
                    ]"
                  >
                    <img
                      v-if="projectDetails.complete_with_gap_financing == 'YES'"
                      src="../assets/icons/check-w.svg"
                      alt=""
                    />
                    <img
                      v-else
                      src="../assets/icons/alert-triangle.svg"
                      alt=""
                    />
                    <div
                      v-if="projectDetails.complete_with_gap_financing == 'YES'"
                      class="c-strike"
                      :class="[]"
                    ></div>
                  </span>
                  <div>
                    <p>Guarantor’s approval</p>
                    <small v-if="projectDetails.guarantor_verification_status != 'verified'"
                      >Wrong data entry
                      <a
                        @click="
                          updateObject();
                          updateG = true;
                          updateB = false;
                        "
                        href=""
                        data-toggle="modal"
                        data-target="#updateBG"
                        >Update</a
                      ></small
                    >
                  </div>
                </div>
                <h6
                  v-if="projectDetails.complete_with_gap_financing == 'YES'"
                  class=""
                  :class="[
                    projectDetails.guarantor_verification_status == 'pending'
                      ? 'pending-text'
                      : 'completed-text',
                    projectDetails.guarantor_verification_status == 'failed'
                      ? 'failed-text'
                      : ''
                  ]"
                >
                  {{ projectDetails.guarantor_verification_status }}
                </h6>
              </div>
              <div
                class="status-set"
                v-if="
                  projectDetails.complete_with_gap_financing == 'YES' &&
                    projectDetails.beneficiary_verification_status ==
                      'pending' &&
                    projectDetails.guarantor_verification_status == 'pending'
                "
              >
                <div class="status-mark">
                  <span class="pending-mark"
                    ><img src="../assets/icons/rotate-ccw.svg" alt="" />
                    <!-- <div class="c-strike"></div> -->
                  </span>
                  <div>
                    <p>Process completion</p>
                    <small
                      >This will have to go through a step by step process
                      before it is processed</small
                    >
                  </div>
                </div>
                <h6
                  v-if="projectDetails.complete_with_gap_financing == 'YES'"
                  class=""
                  :class="[
                    projectDetails.paymentprocesscompleted == 'NO'
                      ? 'pending-text'
                      : 'completed-text'
                  ]"
                >
                  {{
                    projectDetails.paymentprocesscompleted == "NO"
                      ? "Pending"
                      : "Verified"
                  }}
                </h6>
              </div>
            </div>
            <div class="payment-breakdown-text">
              <h5 @click="paymentBreakDown(project.actualpaymemtlog)">
                My payment breakdown
              </h5>
            </div>
          </div>
        </form>
      </div>
      <!-- Don't remove -->
      <div class="clearfix"></div>

      <!-- Modal to fund project from safe begins -->
      <div
        class="modal fade"
        id="fundproject"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div
            class="modal-content fundmodalContent"
            style="margin-bottom: 50px;"
          >
            <div class="closeFundProject" style="border-radius: 20px 20px 0 0;">
              <h6>Transfer funds to project</h6>
              <small data-dismiss="modal" aria-label="Close">x</small>
            </div>
            <div class="modal-body">
              <p class="transferSafeBalance">
                Safe Balance:
                <span>
                  &#8358;{{
                    Number(safeDetails.balance).toLocaleString()
                  }}</span
                >
              </p>
              <form>
                <div class="form-group">
                  <!-- <label for="exampleInputEmail1">Email address</label> -->
                  <input
                    onfocus="(this.type='text')"
                    type="number"
                    class="form-control"
                    id="amountfund"
                    placeholder="Enter amount"
                    v-model="transferAmount"
                  />
                </div>

                <div class="form-group transfer-btn">
                  <button class="btn btn-block" @click.prevent="fundProject">
                    Transfer
                    <i
                      class="fa fa-spinner fa-spin ml-3 f-12 btn-loader"
                      v-if="fundLoader"
                    ></i>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- Modal to fund project from safe ends -->

      <!-- <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
       ...
      </div> -->
      <!-- Modal for Payout begins -->
      <div
        class="modal fade"
        id="payout"
        tabindex="-1"
        role="dialog"
        aria-labelledby="modalPayout"
        aria-hidden="true"
      >
        <div
          class="modal_wrap modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable"
          role="document"
        >
          <div class="modal-content">
            <div class="closeFundProject" style="border-radius: 20px 20px 0 0;">
              <h6>PAYOUT</h6>
              <small data-dismiss="modal" aria-label="Close">x</small>
            </div>

            <!-- Payout details begins -->
            <div class="modal-body" v-if="option === 1">
              <div class="payout_details">
                <h2 v-if="projectDetails.amounttype === 'fixed'">
                  ₦{{ Number(projectDetails.amountforfixed).toLocaleString() }}
                </h2>
                <h2 v-if="projectDetails.amounttype === 'range'">
                  ₦{{
                    Number(projectDetails.maxamountforrange).toLocaleString()
                  }}
                </h2>
                <small>You are eligible for gap financing</small>
                <div class="form-group form-check mb-1 mt-2">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    id="gap"
                    v-model="gapfinacing"
                    true-value="YES"
                    false-value="NO"
                  />
                  <label class="form-check-label" for="gap"
                    >Complete payment with Gap financing</label
                  >
                </div>
                <small v-if="projectDetails.amountleft == 0"
                  >Shelta can help you pay the remaining ₦{{
                    Number(project.amountleft).toLocaleString()
                  }}</small
                >
              </div>
              <hr />

              <form>
                <div class="beneficiary_holder mb-5">
                  <div class="text_holders">
                    <h5>Beneficiary</h5>
                    <h6
                      v-if="beneficiciaryToggle && beneficiaryList.length > 0"
                      @click="beneficiciaryToggle = false"
                    >
                      Select Beneficiary
                    </h6>
                    <h6
                      v-if="!beneficiciaryToggle"
                      @click="beneficiciaryToggle = true"
                    >
                      Add New
                    </h6>
                  </div>

                  <div v-if="beneficiciaryToggle">
                    <div class="row">
                      <div class="col-md-4">
                        <div class="form-group">
                          <input
                            type="text"
                            class="form-control"
                            :class="{
                              errorData: info && beneficiaryaccountname === ''
                            }"
                            id="acccountname"
                            placeholder="Account Name"
                            v-model="beneficiaryaccountname"
                          />
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group">
                          <input
                            type="number"
                            class="form-control"
                            maxlength="10"
                            :class="{
                              errorData: info && beneficiaryaccountnumber === ''
                            }"
                            id="accountNumber"
                            placeholder="Account Number"
                            v-model="beneficiaryaccountnumber"
                          />
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group">
                          <select
                            class="form-control"
                            id="bank"
                            v-model="beneficiarybank"
                            :class="{
                              errorData: info && beneficiarybank === ''
                            }"
                          >
                            <option selected disabled value=""
                              >Select Bank</option
                            >
                            <option
                              v-for="(item, index) in banks"
                              :key="index"
                              >{{ item }}</option
                            >
                          </select>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group">
                          <input
                            type="email"
                            class="form-control"
                            id="email1"
                            placeholder="Email Address"
                            v-model="beneficiaryemail"
                            :class="{
                              errorData: info && beneficiaryemail === ''
                            }"
                          />
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group">
                          <input
                            type="number"
                            class="form-control"
                            id="phone"
                            placeholder="Phone Number"
                            v-model="beneficiaryphone"
                            :class="{
                              errorData: info && beneficiaryphone === ''
                            }"
                          />
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group">
                          <textarea
                            type="text"
                            class="form-control"
                            :class="{
                              errorData: info && beneficiaryremark === ''
                            }"
                            id="remarks"
                            placeholder="Remarks"
                            v-model="beneficiaryremark"
                          >
                          </textarea>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div v-else>
                    <div>
                      <div>
                        <div
                          class="beneficiary_details"
                          :class="[
                            index === beneficiaryIndex ? 'activeOption' : ''
                          ]"
                          v-for="(item, index) in beneficiaryList"
                          :key="index"
                          @click="
                            beneficiaryIndex = index;
                            asignBeneficiaryData(item);
                          "
                        >
                          <h5>{{ item.initials }}</h5>
                          <div class="details_holder">
                            <div>
                              <h6>{{ item.accountname }}</h6>
                              <h6>{{ item.phone }}</h6>
                              <h6>{{ item.bank }}</h6>
                            </div>
                            <p>x</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="guarantor_holder">
                  <div class="text_holders">
                    <h5>Guarantor</h5>
                    <h6
                      v-if="guarantorToggle && guarantorList.length > 0"
                      @click="guarantorToggle = false"
                    >
                      Select Guarantor
                    </h6>
                    <h6 v-if="!guarantorToggle" @click="guarantorToggle = true">
                      Add New
                    </h6>
                  </div>

                  <div v-if="guarantorToggle">
                    <div class="row">
                      <div class="col-md-4">
                        <div class="form-group">
                          <input
                            type="text"
                            class="form-control"
                            id="fname2"
                            placeholder="Fullname"
                            v-model="guarantorfullname"
                            :class="{
                              errorData: info && guarantorfullname === ''
                            }"
                          />
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group">
                          <input
                            type="email"
                            class="form-control"
                            id="email2"
                            placeholder="Email"
                            v-model="guarantoremail"
                            :class="{
                              errorData: info && guarantoremail === ''
                            }"
                          />
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group">
                          <input
                            type="number"
                            class="form-control"
                            id="phone2"
                            placeholder="Phone Number"
                            v-model="guarantorphone"
                            :class="{
                              errorData: info && guarantorphone === ''
                            }"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <input
                            type="text"
                            class="form-control"
                            id="relationship"
                            placeholder="Relationship"
                            v-model="guarantorrelationship"
                            :class="{
                              errorData: info && guarantorrelationship === ''
                            }"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <textarea
                            type="text"
                            class="form-control"
                            id="address"
                            placeholder="Home Address"
                            v-model="guarantoraddress"
                            :class="{
                              errorData: info && guarantoraddress === ''
                            }"
                          >
                          </textarea>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div v-else>
                    <div>
                      <div>
                        <div
                          class="guarantor_details"
                          :class="[
                            index === guarantorIndex ? 'activeOption2' : ''
                          ]"
                          v-for="(item, index) in guarantorList"
                          :key="index"
                          @click="
                            guarantorIndex = index;
                            asignGuarantorData(item);
                          "
                        >
                          <h5>{{ item.initials }}</h5>
                          <div class="details_holder">
                            <div>
                              <h6>{{ item.fullname }}</h6>
                              <h6>{{ item.phone }}</h6>
                              <h3>{{ item.email }}</h3>
                            </div>
                            <p>x</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>

            <div class="container navigate_btn" v-if="option === 1">
              <button
                class="btn close"
                data-dismiss="modal"
                @click="
                  option = 1;
                  info = false;
                  beneficiaryIndex = '';
                  guarantorIndex = '';
                "
              >
                Cancel
              </button>
              <button class="btn proceed" @click="proceedBtn(2)">
                Proceed
              </button>
            </div>
            <!-- Payout details ends -->

            <!-- Payout summary begins -->
            <div class="modal-body" v-if="option === 2">
              <div class="top_section_summary">
                <p>Transfer</p>
                <h3 v-if="projectDetails.amounttype === 'fixed'">
                  ₦{{ Number(projectDetails.amountforfixed).toLocaleString() }}
                </h3>
                <h3 v-if="projectDetails.amounttype === 'range'">
                  ₦{{
                    Number(projectDetails.maxamountforrange).toLocaleString()
                  }}
                </h3>
                <small
                  >Completed with SAFE Gap financing: ₦{{
                    Number(project.amountleft).toLocaleString()
                  }}</small
                >
                <h6>To</h6>
              </div>

              <div class="payoutDataSummary">
                <h3>Project Beneficiary</h3>

                <div class="row">
                  <div class="col-md-8">
                    <div class="table-responsive">
                      <table class="table table-borderless">
                        <tbody>
                          <tr class="table-data">
                            <th>Account Name</th>
                            <td>{{ beneficiaryaccountname }}</td>
                          </tr>
                          <tr class="table-data">
                            <th>Account Number:</th>
                            <td>{{ beneficiaryaccountnumber }}</td>
                          </tr>
                          <tr class="table-data">
                            <th>Phone Number:</th>
                            <td>{{ beneficiaryphone }}</td>
                          </tr>
                          <tr class="table-data">
                            <th>Email Address:</th>
                            <td>{{ beneficiaryemail }}</td>
                          </tr>
                          <tr class="table-data">
                            <th>Bank:</th>
                            <td>{{ beneficiarybank }}</td>
                          </tr>
                          <tr class="table-data" v-if="beneficiaryremark != ''">
                            <th>Remarks</th>
                            <td>{{ beneficiaryremark }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <hr style="background: #0033ea;" />
                <h3>Project Guarantor</h3>
                <div class="row">
                  <div class="col-md-8">
                    <div class="table-responsive">
                      <table class="table table-borderless">
                        <tbody>
                          <tr class="table-data">
                            <th>Fullname</th>
                            <td>{{ guarantorfullname }}</td>
                          </tr>
                          <tr class="table-data">
                            <th>Home Address:</th>
                            <td>{{ guarantoraddress }}</td>
                          </tr>
                          <tr class="table-data">
                            <th>Phone Number:</th>
                            <td>{{ guarantorphone }}</td>
                          </tr>
                          <tr class="table-data">
                            <th>Email Address:</th>
                            <td>{{ guarantoremail }}</td>
                          </tr>
                          <tr class="table-data">
                            <th>Relationship:</th>
                            <td>{{ guarantorrelationship }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="container navigate_btn" v-if="option === 2">
              <button
                class="btn close"
                data-dismiss="modal"
                @click="option = 1"
              >
                Cancel
              </button>
              <button class="btn proceed" @click="proceedBtn(3)">
                Proceed
              </button>
            </div>
            <!-- Payout summary ends -->

            <!-- Terms and condition for payout -->
            <div class="modal-body" v-if="option === 3">
              <h3 class="termsofuse">TERMS OF USE</h3>
              <p class="termsCondtion">
                <span>PAYOUT FOR RENT</span> – By using this service and
                providing relevant information, your funds will remain in your
                safe account until you initiate a transfer of any amount or all
                of your funds for your project needs or transactions. In order
                for you to be eligible for payout, your Performance Index must
                be up to 220/365 and your Accumulated Project Funds must be up
                to 60% at the time of application. Also, Bank account details
                and other information for designated projects/transactions must
                be verified as provided by you. If for any reason, you are
                unable to meet the 100% Accumulated Project Funds, you can
                request for a payout through our Gap Financing service, provided
                you have saved up to 60% for which you would be charged a
                monthly reducing balance of 2% interest rate if you provide a
                guarantor within the safe community (payable within 1-3 working
                days) and 3% for a guarantor outside the safe community (payable
                within 3-5 working days).
              </p>
              <p class="termsCondtion">
                <span>PAYOUT FOR LAND</span> – By using this service and
                providing relevant information, your funds will remain in your
                safe account until you initiate a transfer of any amount or all
                of your funds for your project needs or transactions. In order
                for you to be eligible for payout, your Performance Index must
                be up to 275/365 and your Accumulated Project Funds must be up
                to 75% at the time of application. Also, Bank account details
                and other information for designated projects/transactions must
                be verified as provided by you. If for any reason, you are
                unable to meet the 100% Accumulated Project Funds, you can
                request for a payout through our Gap Financing service, provided
                you have saved up to 75% for which you would be charged a
                monthly reducing balance of 2% interest rate if you provide a
                guarantor within the safe community (payable within 1-3 working
                days) and 3% for a guarantor outside the safe community (payable
                within 3-5 working days).
              </p>
              <p class="termsCondtion">
                <span>PAYOUT FOR MORTGAGE</span> – By using this service and
                providing relevant information, your funds will remain in your
                safe account until you initiate a transfer of any amount or all
                of your funds for your project needs or transactions. In order
                for you to be eligible for payout, your Performance Index must
                be up to 146/365 and your Accumulated Project Funds must be up
                to 40% at the time of application. Also, Bank account details
                and other information for designated projects/transactions must
                be verified as provided by you. If for any reason, you are
                unable to meet the 100% Accumulated Project Funds, you can
                request for a payout through our Gap Financing service, provided
                you have saved up to 40% for which you would be charged a
                monthly reducing balance of 2% interest rate if you provide a
                guarantor within the safe community (payable within 1-3 working
                days) and 3% for a guarantor outside the safe community (payable
                within 3-5 working days).
              </p>
              <p class="termsCondtion">
                <span>PAYOUT FOR PROPERTY</span> – By using this service and
                providing relevant information, your funds will remain in your
                safe account until you initiate a transfer of any amount or all
                of your funds for your project needs or transactions. In order
                for you to be eligible for payout, your Performance Index must
                be up to 292/365 and your Accumulated Project Funds must be up
                to 80% at the time of application. Also, Bank account details
                and other information for designated projects/transactions must
                be verified as provided by you. If for any reason, you are
                unable to meet the 100% Accumulated Project Funds, you can
                request for a payout through our Gap Financing service, provided
                you have saved up to 80% for which you would be charged a
                monthly reducing balance of 2% interest rate if you provide a
                guarantor within the safe community (payable within 1-3 working
                days) and 3% for a guarantor outside the safe community (payable
                within 3-5 working days).
              </p>
              <p class="termsCondtion">
                <span>ON GAP FINANCING</span> – B– if a user applies for the GAP
                FINANCING service on SAFE, the requested funds would be advanced
                to the provider of that particular project; after all necessary
                verifications and confirmations has been done and not to the
                applicant personally.
                <br />
                <span>SAFE DISCLAIMER!</span>
                Shelta has made every attempt to ensure the accuracy and
                reliability of the information provided on the SAFE portal.
                However, the information provided on this portal, is acted upon
                “as is” without warranty of any kind. Shelta does not accept any
                responsibility or liability for the accuracy, completeness,
                legality or reliability of the information provided on this
                portal. Shelta shall not be liable for any loss or damages of
                whatever nature (direct or indirect), consequential upon the
                information provided on this portal whether arising in contract,
                tort or otherwise
              </p>
              <div class="form-group form-check mb-1 mt-2 tm-checkbox">
                <input
                  type="checkbox"
                  class="form-check-input"
                  v-model="termscondition"
                  id="tm"
                />
                <label class="form-check-label" for="tm"
                  >I have read and agreed to Shelta’s terms and
                  conditions</label
                >
              </div>
            </div>
            <div v-if="payoutLoading">
              <PageLoader></PageLoader>
            </div>
            <div class="container navigate_btn" v-if="option === 3">
              <button
                :disabled="payoutLoading"
                class="btn close"
                data-dismiss="modal"
                @click="option = 1"
              >
                Cancel
              </button>
              <button
                :disabled="
                  !termscondition ||
                    payoutLoading ||
                    projectDetails.payoutsubmitted === 'YES'
                "
                class="btn proceed"
                @click="createBenefeciaryAndGuarantor"
              >
                Payout
                <i
                  class="fa fa-spinner fa-spin  ml-3 f-12  btn-loader"
                  v-if="payoutLoading"
                ></i>
              </button>
            </div>
            <!-- Terms and condition for payout -->
          </div>
        </div>
      </div>
      <!-- Modal for Payout ends -->

      <!-- Modal to end safe project begins -->
      <div
        class="modal fade"
        id="endproject"
        tabindex="-1"
        aria-labelledby="endproject"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content" style="margin-bottom: 50px;">
            <div class="modal-header end_project_header">
              <h5 class="modal-title" id="endproject">
                End Project
              </h5>
              <h3 data-dismiss="modal" @click="hideWarning = false">x</h3>
            </div>
            <div class="modal-body">
              <div
                class="end_project_warning d-none"
                v-if="
                  !hideWarning && endTransferOption === 'withdraw' && apf < 100
                "
              >
                <div class="warning">
                  <small>!</small>
                  <h6>
                    Please note, ending project before time and withdrawing has
                    a penalty attached. 2% charge will be applied: ₦{{
                      Number(
                        (2 * AccumulatedProjectFund) / 100
                      ).toLocaleString()
                    }}
                  </h6>
                  <p @click="hideWarning = true">Ok</p>
                </div>
              </div>
              <form>
                <div class="form-group end_project_holder">
                  <div class="project_title">
                    Project: {{ projectDetails.title }}
                  </div>
                </div>
                <div class="form-group end_project_holder">
                  <textarea
                    type="text"
                    class="form-control"
                    id="reason"
                    rows="3"
                    v-model="reasonforending"
                    placeholder="Why do you want to end this project?"
                  >
                  </textarea>
                </div>
                <hr />
                <div class="endOptionTransfer">
                  <div class="form-check form-group">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="exampleRadios"
                      id="exampleRadios1"
                      value="backtosafe"
                      v-model="endTransferOption"
                    />
                    <label class="form-check-label" for="exampleRadios1">
                      Transfer project fund back to Safe
                    </label>
                  </div>
                  <div class="form-check form-group">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="exampleRadios"
                      id="exampleRadios2"
                      value="fundproject"
                      v-model="endTransferOption"
                    />
                    <label class="form-check-label" for="exampleRadios2">
                      Fund a project
                    </label>
                  </div>

                  <div class="form-check form-group">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="exampleRadios"
                      id="exampleRadios3"
                      value="withdraw"
                      v-model="endTransferOption"
                    />
                    <label class="form-check-label" for="exampleRadios3">
                      Withdraw
                    </label>
                  </div>
                  <div
                    class="form-group"
                    v-if="endTransferOption === 'fundproject'"
                  >
                    <select
                      class="form-control"
                      id="projects"
                      v-model="projectId"
                    >
                      <option slected disabled value="">Select project</option>
                      <option
                        v-for="(item, index) in projects"
                        v-show="
                          item.id != projectDetails.id &&
                            item.APFpercentage != '100%' &&
                            !AccumulatedProjectFund <= item.amountleft
                        "
                        :key="index"
                        :value="item.id"
                        >{{ item.title }} {{ item.id }}</option
                      >
                    </select>
                  </div>
                  <div v-if="endTransferOption === 'withdraw'">
                    <p class="endProjectBalance">
                      Project Balance: ₦{{ AccumulatedProjectFund }}
                    </p>
                    <div class="form-group">
                      <select
                        class="form-control"
                        id="projects"
                        v-model="beneficiarybank"
                      >
                        <option slected disabled value=""
                          >Select Bank Name</option
                        >
                        <option
                          v-for="(item, index) in banks"
                          :key="index"
                          :value="item"
                          >{{ item }}</option
                        >
                      </select>
                    </div>
                    <div class="form-group end_project_holder">
                      <input
                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                        type="number"
                        class="form-control"
                        id="accountnumber"
                        v-model="accountnumber2"
                        maxlength="10"
                        placeholder="Account Number"
                      />
                    </div>
                    <div class="form-group end_project_holder">
                      <input
                        type="text"
                        class="form-control"
                        id="accountname"
                        v-model="accountname2"
                        placeholder="Account Name"
                      />
                    </div>
                  </div>
                </div>
                <div
                  class="end_project_warning"
                  v-if="
                    !hideWarning &&
                      endTransferOption === 'withdraw' &&
                      apf < 100
                  "
                >
                  <div class="warning">
                    <small>!</small>
                    <h6>
                      Please note, ending project before time and withdrawing
                      has a penalty attached. 2% charge will be applied: ₦{{
                        Number(
                          (2 * AccumulatedProjectFund) / 100
                        ).toLocaleString()
                      }}
                    </h6>
                    <p @click="hideWarning = true">Ok</p>
                  </div>
                </div>
              </form>
            </div>

            <div class="modal-footer" style="border-top: none; display: block;">
              <div class="end_btn_holder">
                <button
                  type="button"
                  class="btn btn-block"
                  @click="endProject"
                  :disabled="endProjectLoader"
                >
                  <span>End Project</span>
                  <i
                    class="fa fa-spinner fa-spin ml-3 f-12 btn-loader"
                    v-if="endProjectLoader"
                  ></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Modal to end safe project ends -->

      <!-- Modal to edit beneficiary and guarantor deteils -->
      <div
        class="modal fade"
        id="updateBG"
        tabindex="-1"
        role="dialog"
        aria-labelledby="modalPayout"
        aria-hidden="true"
      >
        <div
          class="modal_wrap modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable"
          role="document"
        >
          <div class="modal-content">
            <div class="closeFundProject" style="border-radius: 20px 20px 0 0;">
              <h6>UPDATE DETEILS</h6>
              <small data-dismiss="modal" aria-label="Close">x</small>
            </div>

            <!-- Beneficiary and guarantor details begins -->
            <div class="modal-body">
              <form>
                <div class="beneficiary_holder mb-5" v-if="updateB">
                  <div class="text_holders">
                    <h5>Beneficiary</h5>
                    <!-- <h6
                      v-if="beneficiciaryToggle && beneficiaryList.length > 0"
                      @click="beneficiciaryToggle = false"
                    >
                      Select Beneficiary
                    </h6>
                    <h6
                      v-if="!beneficiciaryToggle"
                      @click="beneficiciaryToggle = true"
                    >
                      Add New
                    </h6> -->
                  </div>

                  <div>
                    <div class="row">
                      <div class="col-md-4">
                        <div class="form-group">
                          <input
                            type="text"
                            class="form-control"
                            :class="{
                              errorData: info && updateAccountName === ''
                            }"
                            id="acccountname2"
                            placeholder="Account Name"
                            v-model="updateAccountName"
                          />
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group">
                          <input
                            type="number"
                            class="form-control"
                            maxlength="10"
                            :class="{
                              errorData: info && updateAccountNumber === ''
                            }"
                            id="accountNumber2"
                            placeholder="Account Number"
                            v-model="updateAccountNumber"
                          />
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group">
                          <select
                            class="form-control"
                            id="bank2"
                            v-model="updateBank"
                            :class="{
                              errorData: info && updateBank === ''
                            }"
                          >
                            <option selected disabled value=""
                              >Select Bank</option
                            >
                            <option
                              v-for="(item, index) in banks"
                              :key="index"
                              >{{ item }}</option
                            >
                          </select>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group">
                          <input
                            type="email"
                            class="form-control"
                            id="email2"
                            placeholder="Email Address"
                            v-model="updateEmail"
                            :class="{
                              errorData: info && updateEmail === ''
                            }"
                          />
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group">
                          <input
                            type="number"
                            class="form-control"
                            id="phone2"
                            placeholder="Phone Number"
                            v-model="updatePhone"
                            :class="{
                              errorData: info && updatePhone === ''
                            }"
                          />
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group">
                          <textarea
                            type="text"
                            class="form-control"
                            :class="{
                              errorData: info && updateRemarks === ''
                            }"
                            id="remarks3"
                            placeholder="Remarks"
                            v-model="updateRemarks"
                          >
                          </textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="guarantor_holder" v-if="updateG">
                  <div class="text_holders">
                    <h5>Guarantor</h5>
                  </div>

                  <div v-if="guarantorToggle">
                    <div class="row">
                      <div class="col-md-4">
                        <div class="form-group">
                          <input
                            type="text"
                            class="form-control"
                            id="fname3"
                            placeholder="Fullname"
                            v-model="updateGfullname"
                            :class="{
                              errorData: info && updateGfullname === ''
                            }"
                          />
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group">
                          <input
                            type="email"
                            class="form-control"
                            id="email3"
                            placeholder="Email"
                            v-model="updateGemail"
                            :class="{
                              errorData: info && updateGemail === ''
                            }"
                          />
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group">
                          <input
                            type="number"
                            class="form-control"
                            id="phone3"
                            placeholder="Phone Number"
                            v-model="updateGphone"
                            :class="{
                              errorData: info && updateGphone === ''
                            }"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <input
                            type="text"
                            class="form-control"
                            id="relationship3"
                            placeholder="Relationship"
                            v-model="updateGrelationship"
                            :class="{
                              errorData: info && updateGrelationship === ''
                            }"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <textarea
                            type="text"
                            class="form-control"
                            id="address2"
                            placeholder="Home Address"
                            v-model="updateGaddress"
                            :class="{
                              errorData: info && updateGaddress === ''
                            }"
                          >
                          </textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="container navigate_btn">
                  <button class="btn close" data-dismiss="modal">
                    Cancel
                  </button>
                  <button
                    class="btn proceed"
                    @click="updateCheck()"
                    :disabled="updateLoading"
                  >
                    {{ updateLoading ? "Processing" : "Update" }}
                    <i
                      class="fa fa-spinner fa-spin ml-3 f-12 btn-loader"
                      v-if="updateLoading"
                    ></i>
                  </button>
                </div>
              </form>
            </div>
            <!-- Beneficiary and guarantor details ends -->
          </div>
        </div>
      </div>
      <!-- Modal to edit beneficiary and guarantor deteils -->
    </div>
  </div>
</template>

<script>
import SmallNav from "./SmallNav";
import PageLoader from "./PageLoader.vue";
import { Service } from "../store/service";
const Api = new Service();
import $ from "jquery";
import moment from "moment";
import Vue from "vue";

export default {
  name: "SafeProjectDetails",
  // props: ["page"],
  components: {
    SmallNav,
    PageLoader
    // Footer,
  },
  data() {
    return {
      loading: false,
      payoutLoading: false,
      updateLoading: false,
      fundLoader: false,
      updateG: false,
      updateB: false,
      beneficiciaryToggle: true,
      guarantorToggle: true,
      info: false,
      benInvalidEmail: false,
      guaInvalidEmail: false,
      termscondition: false,
      endProjectLoader: false,
      hideWarning: false,
      projectEnded: false,
      option: 1,
      project: "",
      projectDetails: "",
      cs: "",
      pi: "",
      apf: "",
      AccumulatedProjectFund: "",
      safeDetails: "",
      projectid: "",
      transferAmount: "",
      banks: [],
      beneficiaryIndex: "",
      guarantorIndex: "",
      beneficiaryfullname: "",
      beneficiaryaccountnumber: "",
      beneficiaryaccountname: "",
      beneficiaryphone: "",
      beneficiaryemail: "",
      beneficiarybank: "",
      beneficiaryremark: "",
      guarantoraddress: "",
      guarantorrelationship: "",
      guarantorphone: "",
      guarantoremail: "",
      guarantorfullname: "",
      beneficiaryid: "",
      guarantorid: "",
      gapfinacing: "NO",
      beneficiaryList: [],
      guarantorList: [],
      projectEndDateDuration: "",
      endTransferOption: "",
      projectId: "",
      projects: [],
      user: "",
      url: "",
      accountnumber2: "",
      accountname2: "",
      reasonforending: "",
      payload: {},
      weekly: "",
      daily: "",
      monthly: "",

      updateAccountName: "",
      updateAccountNumber: "",
      updateBank: "",
      updateEmail: "",
      updatePhone: "",
      updateRemarks: "",

      updateGrelationship: "",
      updateGemail: "",
      updateGphone: "",
      updateGfullname: "",
      updateGaddress: ""
    };
  },
  watch: {
    transferAmount: function(newValue) {
      const result = newValue
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      Vue.nextTick(() => (this.transferAmount = result));
    }
  },

  mounted() {
    this.user = JSON.parse(localStorage.getItem("shelta-user"));
    this.fetchProjectDetails();
    this.getBankNames();
    this.fetchBeneficiaryGuarantor();
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    // hideWarning() {
    //   this.$refs.ok.display = "none";
    // },
    calculateDate() {
      // Weekly pick
      const oneweek = 1000 * 60 * 60 * 24 * 7;
      const d1 = new Date(this.projectDetails.startdate).getTime();
      const d2 = new Date(this.projectDetails.enddate).getTime();
      const difference = Math.abs(d2 - d1);
      this.weekly = Math.floor(difference / oneweek + 1);
      // console.log("weeks", Math.floor(difference / oneweek), this.weekly);
      // return Math.floor(difference / oneweek);

      // Daily pick
      const day = 1000 * 60 * 60 * 24;
      const date1 = new Date(this.projectDetails.startdate).getTime();
      const date2 = new Date(this.projectDetails.enddate).getTime();
      const difference2 = Math.abs(date2 - date1);
      this.daily = Math.floor(difference2 / day + 1);
      // console.log("Days", Math.floor(difference2 / day), this.daily);
      // return Math.floor(difference_ms / ONE_WEEK);

      // Monthly pick
      let months;
      months =
        (new Date(this.projectDetails.enddate).getYear() -
          new Date(this.projectDetails.startdate).getYear()) *
        12;
      months -= new Date(this.projectDetails.startdate).getMonth();
      months += new Date(this.projectDetails.enddate).getMonth();
      this.monthly = months <= 0 ? 0 : months + 1;
    },
    updateObject() {
      this.updateAccountName = this.project.project_beneficiary.accountname;
      this.updateAccountNumber = this.project.project_beneficiary.accountnumber;
      this.updateBank = this.project.project_beneficiary.bank;
      this.updateEmail = this.project.project_beneficiary.email;
      this.updatePhone = this.project.project_beneficiary.phone;
      this.updateRemarks = this.project.project_beneficiary.remark;

      this.updateGphone = this.project.project_guarantor.phone;
      this.updateGemail = this.project.project_guarantor.email;
      this.updateGaddress = this.project.project_guarantor.address;
      this.updateGfullname = this.project.project_guarantor.fullname;
      this.updateGrelationship = this.project.project_guarantor.relationship;
    },
    updateCheck() {
      if (this.updateB == true) {
        this.updateBeneficiarydata();
      }
      if (this.updateG == true) {
        this.updateGurantordata();
      }
    },
    async updateBeneficiarydata() {
      this.updateLoading = true;
      try {
        await Api.postRequest(`updateprojectbeneficiarydata`, {
          accountname: this.updateAccountName,
          accountnumber: this.updateAccountNumber,
          phone: this.updatePhone,
          email: this.updateEmail,
          bank: this.updateBank,
          remark: this.updateRemarks,
          projectid: this.projectDetails.id
        })
          .then(res => {
            // console.log("Update beneficiary response", res);
            this.updateLoading = false;
            $("#updateBG")
              .removeClass("fade")
              .modal("hide");
            if (res.data.success) {
              this.getProjects();
              return this.$store.commit("setApiSuccess", "Success");
            }
            if (res.data.error) {
              return this.$store.commit("setApiFailed", res.data.error);
            }
          })
          .catch(err => {
            this.updateLoading = false;
            console.log(err);
          });
      } finally {
        // console.log("closed");
      }
    },
    async updateGurantordata() {
      this.updateLoading = true;
      try {
        await Api.postRequest(`updateprojectguarantordata`, {
          address: this.updateGaddress,
          relationship: this.updateGrelationship,
          phone: this.updateGphone,
          email: this.updateGemail,
          fullname: this.updateGfullname,
          projectid: this.projectDetails.id
        })
          .then(res => {
            // console.log("Update guarantor response", res);
            this.updateLoading = false;
            $("#updateBG")
              .removeClass("fade")
              .modal("hide");
            if (res.data.success) {
              this.getProjects();
              return this.$store.commit("setApiSuccess", "Success");
            }
            if (res.data.error) {
              return this.$store.commit("setApiFailed", res.data.error);
            }
          })
          .catch(err => {
            this.updateLoading = false;
            console.log(err);
          });
      } finally {
        // console.log("closed");
      }
    },
    paymentBreakDown(data) {
      if (data.length > 0) {
        Api.pushPaymentBreakdown(data);
        this.$router.push({
          path: `/dashboard/paymentbreakdown`
        });
      } else {
        return this.$store.commit("setApiFailed", "No payment records");
      }
    },
    checkIfProjectIsEnded() {
      const olddate = this.projectDetails.enddate;
      const newdate = new Date().getTime();
      if (olddate === newdate) {
        this.projectEnded = false;
      } else {
        this.projectEnded = true;
      }
    },
    async endProject() {
      this.endProjectLoader = true;
      if (this.reasonforending === "") {
        this.endProjectLoader = false;
        return this.$store.commit("setApiFailed", "Fill all required feilds");
      }
      if (this.endTransferOption === "") {
        this.endProjectLoader = false;
        return this.$store.commit("setApiFailed", "select type of transfer");
      }
      if (this.endTransferOption === "backtosafe") {
        if (this.reasonforending === "") {
          this.endProjectLoader = false;
          return this.$store.commit("setApiFailed", "Fill all required feilds");
        }
        this.url = "endprojectandtransfermoneytosafe";
        this.payload.reasonforending = this.reasonforending;
        this.payload.projectid = this.projectDetails.id;
      }
      if (this.endTransferOption === "fundproject") {
        if (this.projectId === "") {
          this.endProjectLoader = false;
          return this.$store.commit("setApiFailed", "select a project");
        }
        this.url = "endprojectandtransfermoneytoanotherproject";
        this.payload.projectid = this.projectDetails.id;
        this.payload.beneficiary_project_id = this.projectId;
        this.payload.reasonforending = this.reasonforending;
      }
      if (this.endTransferOption === "withdraw") {
        if (
          this.accountname2 === "" ||
          this.accountnumber2 === "" ||
          this.beneficiarybank === "" ||
          this.reasonforending === ""
        ) {
          this.endProjectLoader = false;
          return this.$store.commit("setApiFailed", "select all fields");
        }
        this.url = "endprojectandcollectmoney";
        this.payload.projectid = this.projectDetails.id;
        this.payload.accountname = this.accountname2;
        this.payload.accountnumber = this.accountnumber2;
        this.payload.bank = this.beneficiarybank;
        this.payload.reasonforending = this.reasonforending;
      }
      try {
        await Api.postRequest(`${this.url}`, this.payload)
          .then(res => {
            this.accountname2 = "";
            this.accountnumber2 = "";
            this.beneficiarybank = "";
            this.projectId = "";
            this.reasonforending = "";
            this.endProjectLoader = false;
            if (res.data.success) {
              return this.$store.commit("setApiSuccess", "Project ended");
            }
            if (res.data.error) {
              return this.$store.commit("setApiFailed", res.data.error);
            }
          })
          .catch(err => {
            console.log("err", err);
            this.endProjectLoader = false;
          });
      } finally {
        // consoe.log("cosed");
      }
    },
    getProjects() {
      Api.getRequest(`getusersafeprojectsbyuserid/${this.user.id}`)
        .then(res => {
          // console.log("Projects", res);
          if (res.data.success) {
            this.projects = res.data.projects;
            if (res.data.incomplete_project != null) {
              this.projects.push(res.data.incomplete_project);
            }
          }
          if (res.data.error) {
            this.$store.commit("setApiFailed", res.data.error);
          }
        })
        .catch(err => {
          console.log("err", err);
        });
    },
    proceedBtn(data) {
      if (data === 2) {
        if (
          this.beneficiaryaccountname === "" ||
          this.beneficiaryaccountnumber === "" ||
          this.beneficiaryphone === "" ||
          this.beneficiaryemail === "" ||
          this.beneficiaryremark === "" ||
          this.beneficiarybank === "" ||
          this.guarantoraddress === "" ||
          this.guarantorrelationship === "" ||
          this.guarantorphone === "" ||
          this.guarantoremail === "" ||
          this.guarantorfullname === ""
        ) {
          this.info = true;
          // this.loader = false;
          return this.$store.commit("setApiFailed", "Fill all required fields");
        }
        const validRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!validRegex.test(this.beneficiaryemail)) {
          this.info = true;
          this.benInvalidEmail = true;
          return this.$store.commit(
            "setApiFailed",
            "Invalid Beneficiary's Email"
          );
        }

        const validRegex2 = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!validRegex2.test(this.guarantoremail)) {
          this.info = true;
          this.guaInvalidEmail = true;
          return this.$store.commit(
            "setApiFailed",
            "Invalid Guarantor's Email"
          );
        }
        return (this.option = 2);
      }
      if (data === 3) {
        return (this.option = 3);
      }
      if (data === 1) {
        return (this.option = 1);
      }
    },
    asignBeneficiaryData(data) {
      this.beneficiaryaccountname = data.accountname;
      this.beneficiaryaccountnumber = data.accountnumber;
      this.beneficiarybank = data.bank;
      this.beneficiaryemail = data.email;
      this.beneficiaryphone = data.phone;
      this.beneficiaryremark = data.remark;
    },
    asignGuarantorData(data) {
      this.guarantoraddress = data.address;
      this.guarantoremail = data.email;
      this.guarantorfullname = data.fullname;
      this.guarantorphone = data.phone;
      this.guarantorrelationship = data.relationship;
    },
    fetchBeneficiaryGuarantor() {
      Api.getRequest(`safebeneficiariesandgurantors`)
        .then(res => {
          if (res.data.success) {
            const beneficiaries = res.data.beneficiaries;
            const guarantors = res.data.gurantors;
            beneficiaries.forEach(element => {
              const y = element.accountname.split(" ");
              const iniName = element.accountname.charAt(0);
              const iniLname = y[y.length - 1].charAt(0);
              const x = iniName + iniLname;
              element.initials = x;
              this.beneficiaryList.push(element);
            });
            guarantors.forEach(element => {
              const y = element.fullname.split(" ");
              const iniName = element.fullname.charAt(0);
              const iniLname = y[y.length - 1].charAt(0);
              const x = iniName + iniLname;
              element.initials = x;
              this.guarantorList.push(element);
            });
          }
          if (res.data.error) {
            return this.$store.commit("setApiFailed", res.data.error);
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    async createBenefeciaryAndGuarantor() {
      this.payoutLoading = true;
      try {
        await Api.postRequest(`addbothsafeprojectbeneficiaryandguarantor`, {
          beneficiaryaccountname: this.beneficiaryaccountname,
          beneficiaryaccountnumber: this.beneficiaryaccountnumber,
          beneficiaryphone: this.beneficiaryphone,
          beneficiaryemail: this.beneficiaryemail,
          beneficiarybank: this.beneficiarybank,
          guarantoraddress: this.guarantoraddress,
          guarantorrelationship: this.guarantorrelationship,
          guarantorphone: this.guarantorphone,
          guarantoremail: this.guarantoremail,
          guarantorfullname: this.guarantorfullname
        })
          .then(res => {
            // console.log("payout response", res);
            this.pageLoader = false;
            if (res.data.success) {
              this.beneficiaryid = res.data.beneficiary.id;
              this.guarantorid = res.data.gurantor.id;
              setTimeout(() => {
                this.payout();
              }, 1000);
              // this.payout();
            }
            if (res.data.error) {
              return this.$store.commit("setApiFailed", res.data.error);
            }
          })
          .catch(err => {
            this.payoutLoading = false;
            console.log(err);
          });
      } finally {
        // console.log("closed");
      }
    },
    async payout() {
      this.payoutLoading = true;
      try {
        await Api.postRequest(`requestpayoutforsafeproject`, {
          projectid: this.projectid,
          beneficiaryid: this.beneficiaryid,
          guarantorid: this.guarantorid,
          remarks: this.beneficiaryremark,
          complete_with_gap_financing: this.gapfinacing,
          gapfinancing: Number(this.project.amountleft)
        })
          .then(res => {
            // console.log("payout done", res);
            this.payoutLoading = false;
            if (res.data.success) {
              $("#payout")
                .removeClass("fade")
                .modal("hide");
              this.option = 1;
              this.fetchProjectDetails();
              this.fetchBeneficiaryGuarantor();
              return this.$store.commit(
                "setApiSuccess",
                "Payout processing, started"
              );
            }
            if (res.data.error) {
              return this.$store.commit("setApiFailed", res.data.error);
            }
          })
          .catch(err => {
            this.payoutLoading = false;
            console.log(err);
          });
      } finally {
        // console.log("closed");
      }
    },
    async getBankNames() {
      try {
        await Api.getRequest(`getbanks`)
          .then(res => {
            // console.log("Banks", res);
            if (res.data.success) {
              this.banks = res.data.banks;
            }
            if (res.data.error) {
              this.$store.commit("setApiFailed", "Can not get banks");
            }
          })
          .catch(err => {
            console.log("Error", err);
          });
      } finally {
        // console.log("closed connect");
      }
    },
    async fetchSafeData() {
      this.pageLoader = true;
      // console.log("are you safe", this.registedForSafe);
      try {
        await Api.getRequest(`fetchsafedata`)
          .then(res => {
            this.pageLoader = false;
            if (res.data.success) {
              // console.log("Safe data", res);
              this.safeDetails = res.data;
            }
            if (res.data.error) {
              this.$store.commit("setApiFailed", "Can not read account");
            }
          })
          .catch(err => {
            this.pageLoader = false;
            console.log(err);
            // this.loader = false;
            // this.uploading = true;
          });
      } finally {
        // console.log("Closed connection");
      }
    },
    getDateForPI() {
      let months;
      months =
        (new Date().getYear() -
          new Date(this.projectDetails.startdate).getYear()) *
        12;
      months -= new Date(this.projectDetails.startdate).getMonth();
      months += new Date().getMonth();
      this.projectEndDateDuration = months <= 0 ? 0 : months;
      // console.log("End date duration", this.projectEndDateDuration);
    },
    async fetchProjectDetails() {
      this.loading = true;
      const id = this.$route.params.id;
      try {
        Api.getRequest(`getprojectdetailsbyid/${id}`)
          .then(res => {
            this.loading = false;
            // console.log("Project details", res);
            if (res.data.success) {
              this.cs = Math.round(res.data.CS);
              this.pi = Math.round(res.data.PI);
              this.apf = Math.round(res.data.APF);
              this.AccumulatedProjectFund = res.data.AccumulatedProjectFund;
              this.projectDetails = res.data.project;
              this.projectid = res.data.project.id;
              // console.log("proejct id", this.projectid);
              this.project = res.data;
              this.fetchSafeData();
              this.getDateForPI();
              this.getProjects();
              this.checkIfProjectIsEnded();
              this.calculateDate();
              // this.projectDetails.guarantor_verification_status = "pending";
              this.$store.commit("setApiSuccess", "Success");
            }
            if (res.data.error) {
              this.$store.commit("setApiFailed", res.data.error);
            }
          })
          .catch(err => {
            console.log(err);
            this.loading = false;
          });
      } finally {
        // console.log("closed");
      }
    },
    async fundProject() {
      this.fundLoader = true;
      if (this.projectDetails.id === "" || this.transferAmount === "") {
        this.fundLoader = false;
        return this.$store.commit("setApiFailed", "All fields are required");
      }
      const formattedAmount = this.transferAmount.split(",").join("");
      if (formattedAmount > this.safeDetails.balance) {
        this.fundLoader = false;
        return this.$store.commit(
          "setApiFailed",
          "Amount entered exceeds safe balance"
        );
      } else {
        try {
          await Api.postRequest(`creditprojectfromsafe`, {
            amount: parseFloat(formattedAmount),
            projectid: this.projectDetails.id
          })
            .then(res => {
              this.fundLoader = false;
              // console.log("Funds transfer succefully", res);
              if (res.data.success) {
                this.$store.commit("setApiSuccess", "Success");
                this.fetchSafeData();
                $("#fundproject")
                  .removeClass("fade")
                  .modal("hide");
              }
              if (res.data.error) {
                this.$store.commit("setApiFailed", res.data.error);
              }
            })
            .catch(err => {
              console.log("err", err);
              this.fundLoader = false;
            });
        } finally {
          console.log("close");
        }
      }
    }
  },
  filters: {
    moment: function(date) {
      return moment(date).format("MMMM Do YYYY, h:mm:ss a");
    }
  }
};
</script>

<style lang="scss" scoped>
$primary: #0033ea;
$secondary: #ffffff;
$yellow: #ffb100;
$tatiary: #a2b0e3;
$black: #000000;
$offblack: #262626;
$limeGreen: #3ec250;

@font-face {
  font-family: "Gotham-bold";
  src: local("Gotham-bold"),
    url("../assets/fonts/shelta_fonts/Gotham\ Bold.otf") format("truetype");
}
@font-face {
  font-family: "Gotham";
  src: local("Gotham"),
    url("../assets/fonts/shelta_fonts/Gotham\ Book.otf") format("truetype");
}
@font-face {
  font-family: "Gotham-medium";
  src: local("Gotham-medium"),
    url("../assets/fonts/shelta_fonts/Gotham\ Medium.otf") format("truetype");
}

@font-face {
  font-family: "Lato";
  src: local("Lato-Regular"),
    url("../assets/fonts/Lato/Lato-Regular.ttf") format("truetype");
}

// Status Update begins

.header-status {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 70px;
  margin-bottom: 24px;
  h5 {
    font-family: Lato;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: 125%;
    color: $offblack;
    margin-bottom: 0;
  }
  h6 {
    color: $primary;
    font-family: Lato;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 125%;
    text-decoration-line: underline;
    margin-bottom: 0;
  }
}
.status-set {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 39px;
}
.status-mark {
  display: flex;
  align-items: flex-start;
  span {
    margin-right: 16px;
  }
  p {
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 125%;
    text-align: left;
    margin-bottom: 4px;
  }
  small {
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    text-align: left;
    display: flex;
    a {
      color: red;
      padding-left: 5px;
      text-decoration: underline;
    }
  }
}
.completed-mark {
  background: #3ec250;
  padding: 2px 6px 4px;
  border-radius: 4px;
  position: relative;
  div {
    position: absolute;
    width: 2px;
    height: 48px;
    background: #3ec250;
    right: 45%;
  }
}
.failed-mark {
  background: #eb0000;
  padding: 0px 6px 4px;
  border-radius: 4px;
  position: relative;
  div {
    position: absolute;
    width: 2px;
    height: 48px;
    background: #eb0000;
    right: 45%;
  }
}
.pending-mark {
  background: #ffb100;
  padding: 2px 6px 4px;
  border-radius: 4px;
  position: relative;
  div {
    position: absolute;
    width: 2px;
    height: 48px;
    background: #ffb100;
    right: 45%;
  }
}
.completed-text {
  background: #e6fbf1;
  color: #3ec250;
  font-family: Gotham;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 0.15px;
  padding: 4px;
  border-radius: 4px;
  width: 62px;
  text-transform: capitalize;
}
.failed-text {
  background: #ffecec;
  color: #eb0000;
  font-family: Gotham;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 0.15px;
  padding: 4px;
  border-radius: 4px;
  width: 62px;
  text-transform: capitalize;
}
.pending-text {
  background: #fff7e3;
  color: #ffb100;
  font-family: Gotham;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 0.15px;
  padding: 4px;
  border-radius: 4px;
  width: 62px;
  text-transform: capitalize;
}

.payment-breakdown-text {
  margin-top: 28px;
  margin-bottom: 24px;
  h5 {
    color: $primary;
    font-family: Lato;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    text-align: left;
    cursor: pointer;
  }
}

@media only screen and (max-width: 599px) {
  .status {
    padding-left: 0;
    padding-right: 0;
  }
  .status-mark {
    span {
      margin-right: 5px;
    }
  }
  .header-status {
    h5 {
      font-size: 18px;
    }
    h6 {
      font-size: 16px;
    }
  }
}
// Status Update ends

.project-top {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-top: 20px;
  margin-bottom: 10px;
}

.back_text {
  cursor: pointer;
  img {
    background: #3059eb;
    border-radius: 3px;
    padding: 5px;
  }
  font-family: Gotham;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  color: $black;
  span {
    color: #1765ca;
    padding-left: 5px;
  }
}

.fund_link {
  h6 {
    background: $limeGreen;
    color: $secondary;
    // padding: 5px 10px;
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    // line-height: 11px;
    border-radius: 4px;
    cursor: pointer;
    img {
      padding-left: 5px;
    }
  }
}

.details_body {
  background: $secondary;
  border-radius: 20px;
  padding-top: 18px;
  padding-bottom: 50px;
  margin-bottom: 30px;
}
.details_top {
  h6 {
    font-family: Gotham;
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 125%;
    color: $offblack;
    text-align: left;
  }
  h3 {
    font-family: Gotham;
    font-style: normal;
    font-weight: 800;
    font-size: 29px;
    line-height: 20px;
    color: $primary;
    text-align: left;
    padding-top: 10px;
  }
  small {
    font-family: Gotham;
    font-style: italic;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: $offblack;
    display: flex;
    text-align: left;
    span {
      background: $black;
      color: $secondary;
      border-radius: 50px;
      padding: 0px 6px;
      margin-left: 8px;
      font-family: Gotham;
    }
  }
  h5 {
    font-family: Gotham;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 150%;
    color: #262626;
    text-align: left;
    padding-top: 15px;
    text-transform: capitalize;
  }
}
.indicies {
  text-align: right;
  margin-top: 20px;
  margin-bottom: 30px;
  display: flex;
  justify-content: right;
  .PI {
    font-family: Gotham;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 20px;
    color: $offblack;
    text-align: right;
    padding: 0 16px;
    position: relative;
  }
  .tool-tip-icon {
    all: revert;
    background: $black !important;
    color: $secondary !important;
    border-radius: 50px;
    padding: 3px 6px !important;
    // margin-left: 3px;
    font-family: Gotham;
    font-size: 13px;
    // position: absolute;
  }
}

.pi-tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
  background: black;
  color: #fff;
  border-radius: 50px;
  padding: 1px 8px;
  font-size: 11px;
  bottom: 4px;
}

.pi-tooltip .tooltiptext {
  // visibility: hidden;
  display: none;
  width: 243px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: 100%;
  // left: 50%;
  left: -200%;
  margin-left: -60px;

  font-family: Gotham;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 125%;
  text-align: left;
  padding: 10px;
}
.pi-tooltip .CS {
  left: -614% !important;
}

.pi-tooltip:hover .tooltiptext {
  // visibility: visible;
  display: block;
}

.pi-tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.theDiv {
  height: auto !important;
  text-align: left !important;
  min-height: 59px !important;
  small {
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 150%;
    color: #8e8e8e;
  }
  p {
    margin-bottom: 0;
    font-family: Gotham;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 125%;
    color: $offblack;
    padding-top: 5px;
    text-transform: capitalize;
  }
}

.clearfix {
  visibility: hidden;
}
.completed_status {
  color: rgb(62, 194, 80) !important;
}
.inactive_status {
  color: #eb0000 !important;
}
.active_status {
  color: #ffb100 !important;
}

.button_holder {
  .end_project {
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 125%;
    text-align: center;
    color: #eb0000;
    border: 1px solid #eb0000;
    height: 48px;
    width: 160px;
    border-radius: 6px;
    box-shadow: none;
    outline: 0;
  }
  .payout {
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 125%;
    text-align: center;
    color: $secondary;
    background: $primary;
    border-radius: 6px;
    height: 48px;
    width: 160px;
    box-shadow: none;
    outline: 0;
  }
}

.button_holder {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
// .tooltip.bottom .tooltip-arrow {
//   top: 0;
//   left: 50%;
//   margin-left: -5px;
//   border-bottom-color: #000000; /* black */
//   border-width: 0 5px 5px;
// }

// Fund project modal begins
.fundmodalContent {
  border-radius: 20px;
  input,
  select {
    border: 1px solid #d2d3d3;
    border-radius: 6px;
    height: 45px;
    box-shadow: none;
    outline: 0;
    // color: #b1b2b2;
    color: $offblack;
    font-size: 15px;
    margin-bottom: 25px;
    font-family: Gotham;
  }
}

.transferSafeBalance {
  font-family: Gotham;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  color: #262626;
  text-align: left;
  padding-top: 10px;
  span {
    color: $primary;
  }
}

.transfer-btn {
  button {
    box-shadow: none;
    outline: 0;
    background: $primary;
    color: $secondary;
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    height: 45px;
  }
}

.closeFundProject {
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;
  align-items: baseline;
  background: #efefef;
  h6 {
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 30px;
    color: $black;
  }
  small {
    font-family: Gotham;
    padding-left: 1px;
    text-align: center;
    background: #c4c4c4;
    color: $black;
    border-radius: 50px;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
}

// Fund project modal ends

// Terms and condtions
.termsofuse {
  font-family: Gotham;
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  color: $offblack;
  text-align: center;
  padding: 20px 0;
}
.termsCondtion {
  font-family: Gotham;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 150%;
  color: $offblack;
  margin-bottom: 0;
  text-align: justify;
  span {
    font-weight: bold;
  }
}
.tm-checkbox {
  text-align: left;
  label {
    text-align: left;
    font-family: Gotham;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 125%;
    color: $offblack;
  }
}
// Terns and condition ends

// Payout modal begins

.modal_wrap {
  margin-bottom: 60px !important;
}
.payout_details {
  text-align: left;
  h2 {
    font-family: Gotham;
    font-style: normal;
    font-weight: 600;
    font-size: 46px;
    line-height: 125%;
    color: $primary;
  }
  small {
    font-family: Gotham;
    font-style: italic;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: $offblack;
  }
  small:last-child {
    color: $primary;
  }
  div {
    label {
      font-family: Gotham;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 125%;
      color: $offblack;
    }
  }
}
.text_holders {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  h5 {
    font-family: Gotham;
    font-style: normal;
    font-weight: 600;
    font-size: 23px;
    line-height: 125%;
    color: $offblack;
  }
  h6 {
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 150%;
    text-align: right;
    color: #0033ea;
    cursor: pointer;
  }
}

.errorData {
  border: 0.5px solid #dc3545 !important;
}
.successData {
  border: 0.5px solid #072aa7 !important;
}

input[type="number"],
input[type="text"],
input[type="email"],
select {
  height: 48px;
  border: 1px solid #ced4da;
  box-shadow: none !important;
  outline: 0 !important;
}
textarea {
  min-height: 48px;
  height: 48px;
  padding-top: 13px;
  box-shadow: none !important;
  outline: 0 !important;
}
select {
  color: #b1b2b2;
}
select:focus {
  border-color: #ced4da;
}
.beneficiary_details {
  display: flex;
  justify-content: left;
  align-items: flex-start;
  border: 0.5px solid #d6d6d6;
  border-radius: 4px;
  margin-bottom: 15px;
  padding: 10px;
  position: relative;
  cursor: pointer;
  h5 {
    margin-bottom: 0;
    width: 56px;
    height: 56px;
    background: #f7f9fe;
    font-family: Gotham;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    color: $primary;
    border-radius: 50px;
    padding: 20px 16px;
    margin-right: 10px;
    text-transform: uppercase;
  }
  h6 {
    margin-bottom: 0;
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: $offblack;
    text-align: left;
    text-transform: capitalize;
  }
  h3 {
    margin-bottom: 0;
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: $offblack;
    text-align: left;
  }
  p {
    margin-bottom: 0;
    // position: absolute;
    // left: 92%;
    // top: 26%;
    border: 1px solid #1e1e1e;
    color: #1e1e1e;
    cursor: pointer;
    /* border-radius: 50px; */
    /* padding: 3px 11px 3px 11px; */
    font-size: 12px;
    width: 20px;
    height: 20px;
    margin: 10px;
    z-index: 10px;
  }
}

.guarantor_details {
  display: flex;
  justify-content: left;
  align-items: flex-start;
  border: 0.5px solid #d6d6d6;
  border-radius: 4px;
  margin-bottom: 15px;
  padding: 10px;
  position: relative;
  cursor: pointer;
  h5 {
    margin-bottom: 0;
    width: 56px;
    height: 56px;
    background: #f7f9fe;
    font-family: Gotham;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    color: $primary;
    border-radius: 50px;
    padding: 20px 16px;
    margin-right: 10px;
    text-transform: uppercase;
  }
  h6 {
    margin-bottom: 0;
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: $offblack;
    text-align: left;
    text-transform: capitalize;
  }
  h3 {
    margin-bottom: 0;
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: $offblack;
    text-align: left;
  }
  p {
    margin-bottom: 0;
    // position: absolute;
    // left: 92%;
    // top: 26%;
    border: 1px solid #1e1e1e;
    color: #1e1e1e;
    cursor: pointer;
    /* border-radius: 50px; */
    /* padding: 3px 11px 3px 11px; */
    font-size: 12px;
    width: 20px;
    height: 20px;
    margin: 10px;
    z-index: 10px;
  }
}
.details_holder {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  word-break: break-word;
}
.beneficiary_details:hover {
  border: 1px solid $black;
}

.guarantor_details:hover {
  border: 1px solid $black;
}

.activeOption {
  border: 1px solid $primary !important;
}
.activeOption2 {
  border: 1px solid $primary !important;
}

.navigate_btn {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  padding-top: 15px;
  // align-items: center;
  .close {
    color: #0029bb;
    border: 1px solid #0029bb;
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 125%;
    width: 160px;
    height: 48px;
    outline: 0;
    box-shadow: none;
  }
  .proceed {
    background: $primary;
    color: $secondary;
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 125%;
    width: 160px;
    height: 48px;
    outline: 0;
    box-shadow: none;
  }
}

// Summary option
.top_section_summary {
  hr {
    background: $primary;
  }
  p {
    font-family: Gotham;
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 125%;
    text-align: center;
    color: #262626;
  }
  h3 {
    font-family: Gotham;
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 125%;
    text-align: center;
    color: $primary;
    margin-bottom: 0;
  }

  small {
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 125%;
    color: $limeGreen;
  }
  h6 {
    font-family: Gotham;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 125%;
    text-align: center;
    color: $offblack;
    margin-top: 10px;
  }
}

.payoutDataSummary {
  h3 {
    font-family: Gotham;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 125%;
    color: #262626;
    text-align: left;
  }
}

.table-data {
  th,
  td {
    text-align: left;
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 150%;
    color: #262626;
  }
  th {
    padding-left: 0;
    padding-bottom: 0;
    // padding-top: 0;
    font-weight: normal;
    width: 33%;
  }
}
// Summary option ends
// Payout modal ends

// End Project modal begins

.end_project_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fafbff;
  border-radius: 20px 20px 0px 0px;
  h5 {
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: $black;
    text-align: left;
    // margin: auto;
  }
  h3 {
    color: $black;
    font-family: Gotham;
    font-style: normal;
    border: 1px solid black;
    border-radius: 50px;
    /* width: 36px; */
    /* height: 34px; */
    font-size: 22px;
    margin-bottom: 0;
    padding: 3px 10px;
    cursor: pointer;
  }
}

.end_project_warning {
  border: 1px solid #ffb100;
  padding: 10px;
  border-radius: 8px;
  .warning {
    display: flex;
    justify-content: space-between;
    align-items: center;
    small {
      border: 1px solid #ffb100;
      color: #ffb100;
      width: 21px;
      height: 21px;
      border-radius: 50px;
      padding: 0 8px;
    }
    h6 {
      font-family: Gotham;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      color: #515054;
      text-align: left;
      padding-left: 10px;
      padding-right: 10px;
    }
    p {
      border: 0.5px solid #d6d6d6;
      border-radius: 4px;
      padding: 5px 15px;
      font-size: 11px;
      margin-bottom: 0;
      cursor: pointer;
    }
  }
}

.end_btn_holder {
  button {
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 125%;
    box-shadow: none;
    outline: 0;
    background: #eb0000;
    color: $secondary;
    border-radius: 6px;
    height: 48px;
  }
}

.end_project_holder {
  margin-top: 15px;
  .project_title {
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    text-align: left;
    font-size: 16px;
    line-height: 150%;
    color: $offblack;
    border: 1px solid #8e8e8e;
    border-radius: 6px;
    padding: 15px 10px;
  }
  textarea {
    background: $secondary;
    border: 1px solid #c4c4c4;
    border-radius: 6px;
    height: inherit !important;
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 26px;
    box-shadow: none;
    outline: 0;
  }
}

.endOptionTransfer {
  text-align: left;
  input[type="radio"] {
    width: 20px;
    height: 20px;
    margin-top: 0.1rem;
  }
  label {
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 125%;
    color: $offblack;
    padding-left: 12px;
  }
  select {
    height: 45px;
    box-shadow: none;
    outline: 0;
    color: #000000;
    font-size: 14px;
    border: 1px solid #d2d3d3;
    border-radius: 6px;
    font-family: Gotham;
  }
}

.endProjectBalance {
  font-family: Gotham;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 125%;
  color: #57595a;
}

// End Project modal ends

// Place holders begins
::-webkit-input-placeholder {
  /* Edge */
  font-family: Gotham;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #b1b2b2;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-family: Gotham;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #b1b2b2;
}

::placeholder {
  font-family: Gotham;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #b1b2b2;
}
// place holders ends

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
@media only screen and (max-width: 599px) {
  .details_top {
    padding-left: 0;
    padding-right: 0;
  }
  .button-row {
    padding-left: 0;
    padding-right: 0;
  }
  .button_holder {
    gap: 20px;
  }
  .end_project,
  .payout {
    width: 130px;
  }
  .indicies {
    display: flex;
    justify-content: left;
  }
  .indicies .PI {
    font-size: 13px;
    margin-bottom: 7px;
    text-align: left;
    padding: 0 6px;
    // new one
    // font-size: 11px;
  }
  .pi-tooltip .CS {
    left: -858% !important;
  }
  .pi-tooltip .tooltiptext {
    left: 60%;
  }
  .pi-tooltip .apftip {
    left: -435%;
  }
  .pi-tooltip .pitip::after {
    left: 10% !important;
  }
  .pi-tooltip .CS::after {
    left: 82% !important;
  }

  .pi-tooltip {
    // New one
    bottom: 4px;
    width: 19px;
    height: 19px;
    padding: 0px 7px;
  }

  .details-row {
    .col-sm-12 {
      padding-left: 0;
      padding-left: 0;
    }
  }

  // Payout modal begins
  .top_section_summary {
    h3 {
      font-size: 30px;
    }
  }
  .payout_details {
    h2 {
      font-size: 28px;
    }
    div {
      label {
        font-size: 12px;
      }
    }
  }
  .text_holders {
    h5 {
      font-size: 16px;
    }
    h6 {
      font-size: 13px;
    }
  }
  .navigate_btn {
    gap: 20px;
  }
  .beneficiary_details {
    // display: block;
    h5 {
      padding: 14px 12px;
      width: 45px;
      height: 45px;
    }
    p {
      margin: 10px 0px 10px 10px;
    }
  }
  .guarantor_details {
    h5 {
      padding: 14px 12px;
      width: 45px;
      height: 45px;
    }
    p {
      margin: 10px 0px 10px 10px;
    }
  }
  // Payout modal ends
}
</style>
